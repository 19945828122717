<template>
    <div>
        <header-slot />
        <ReportsTable />
    </div>
</template>

<script>
import ReportsTable from "@/views/commons/components/reports/tabs/monthly-payment/MonthlyPayment.vue";

export default {
    components: {
        ReportsTable
    }
}

</script>